@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Limelight&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ephesis&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');

.App {
  min-height: 100vh;
  text-align: center;
  background-color: #ebf1ed;
  /*background-image : url("http://api.thumbr.it/whitenoise-361x370.png?background=f4f5e9ff&noise=8f8f8f&density=22&opacity=26");*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #313F51;
}

.heading {
  width: 100%;
}

.App-logo {
  height: 2rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.drink-logo {
  height: 5rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-family: 'Ephesis', cursive;
}

.drink-title {
  /*font-family: 'Ephesis', cursive;*/
  /*font-family: 'Limelight', sans-serif;*/
  font-family: 'Raleway', sans-serif;
}

.drink-ingredients {
  font-family: 'Raleway', sans-serif;
}